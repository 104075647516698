import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
// import Page from '../views/Page.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: (resolve) => require(['../views/Home'], resolve),
    children: [
      {
        path: '/',
        name: 'Content',
        meta: {
          requireAuth: true,
        },
        component: (resolve) => require(['../views/Content'], resolve),
      },
      {
        path: '/Page/:number',
        name: 'Page',
        meta: {
          requireAuth: true,
        },
        component: (resolve) => require(['../views/Page'], resolve),
      },
      {
        path: '/Page',
        name: 'Page',
        meta: {
          requireAuth: true,
        },
        component: (resolve) => require(['../views/Page'], resolve),
      },
      {
        path: '/About',
        name: 'About',
        meta: {
          requireAuth: true,
        },
        component: (resolve) => require(['../views/About'], resolve),
      },
      {
        path: '/Blog',
        name: 'Blog',
        meta: {
          requireAuth: true,
        },
        component: (resolve) => require(['../views/Blog'], resolve),
      },
      {
        path: '/Contact',
        name: 'Contact',
        meta: {
          requireAuth: true,
        },
        component: (resolve) => require(['../views/Contact'], resolve),
      },
      {
        path: '/Clients',
        name: 'Clients',
        meta: {
          requireAuth: true,
        },
        component: (resolve) => require(['../views/Clients'], resolve),
      },
      {
        path: '/Works',
        name: 'Works',
        meta: {
          requireAuth: true,
        },
        component: (resolve) => require(['../views/Works'], resolve),
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
})

export default router
