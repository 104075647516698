import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.config.productionTip = false
// Loading
// import Loading from 'vue-loading-overlay' //component
// import 'vue-loading-overlay/dist/vue-loading.css' //style

// Vue.component('Loading', Loading)

// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap'

window.$ = window.jQuery = require('jquery')
// 引入JS
// import imagesloaded from './utils/jquery.imagesloaded'
// Vue.prototype.imagesloaded = imagesloaded
// import wookmark from './utils/jquery.wookmark'
// Vue.prototype.wookmark = wookmark
Vue.component('loading', VueLoading)
Vue.prototype.$axios = axios
Vue.use(VueAxios, axios)

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
