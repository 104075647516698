<template>
  <div id="app">
    <!-- <loading :active.sync="isLoading"></loading> -->
    <router-view />
  </div>
</template>
<script>
import axios from 'axios'
export default {
  name: 'app',
  data() {
    return {
      //   isLoading: false,
    }
  },
  //   mounted() {
  //     // http request 攔截器
  //     axios.interceptors.request.use(
  //       (config) => {
  //         const token = window.sessionStorage.getItem('token')
  //         // console.log('axios start')
  //         // console.log('config.url:', config.url)
  //         // console.log('Global.$API_URL:', Global.$API_URL + 'api/languages')
  //         if (
  //           config.url != Global.$API_URL + 'api/languages' &&
  //           config.url != Global.$API_URL + 'api/currenies'
  //         ) {
  //           //   console.log('open')

  //           this.isLoading = true
  //         } else {
  //           //   console.log('no open')
  //         }

  //         return config
  //       },
  //       (err) => {
  //         this.isLoading = false
  //         return Promise.reject(err)
  //       }
  //     )

  //     // http response 攔截器
  //     axios.interceptors.response.use(
  //       (response) => {
  //         // console.log('axios end')
  //         this.isLoading = false
  //         return response
  //       },
  //       (error) => {
  //         // if (error.response) {
  //         //   switch (error.response.status) {
  //         //     case 401:
  //         //       // 返回 401 清除token資訊並跳轉到登入頁面
  //         //       store.commit(types.DELTOKEN)
  //         //       router.replace({
  //         //         path: '/login',
  //         //         query: { redirect: router.currentRoute.fullPath },
  //         //       })
  //         //   }
  //         // }
  //         // this.isLoading = false
  //         return Promise.reject(error.response.data) // 返回介面返回的錯誤資訊
  //       }
  //     )
  //   },
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
